export default [
  {
    path: "/logout",
    name: "Logout"
  },

  {
    path: "*",
    redirect: "/404"
  }
];
