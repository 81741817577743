import router from "./router/index";
import store from "./store/index";
import NProgress from "nprogress";
import "nprogress/nprogress.css";
import { getAuth } from "@/utils/auth";
import getPageTitle from "@/utils/get-page-title";

NProgress.configure({ showSpinner: false });

const whiteList = ["Login", "Lookup", "LookupDetail", "Register"];

router.beforeEach(async (to, _from, next) => {
  NProgress.start();
  document.title = getPageTitle(to.name);
  const isAuth = getAuth();
  if (isAuth) {
    if (to.path === "/pages/login") {
      next({ path: "/" });
    } else if (to.path === "/logout") {
      store
        .dispatch("user/logout")
        .then(_res => {
          next({ path: "/pages/login" });
        })
        .catch(_err => next({ path: "/pages/login" }));
    } else {
      const { role, organization } = await store.dispatch("user/getInfo");
      if (to.path === "/") {
        if (role == "S") next("/organizations");
        else next(`/edit/${organization.code}/information`);
      }
      next();
    }
  } else {
    if (whiteList.indexOf(to.name) !== -1) {
      next();
    } else {
      next("/pages/login");
    }
  }
});

router.afterEach(() => {
  NProgress.done();
});
