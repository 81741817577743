import Vue from "vue";
import VueRouter from "vue-router";
import dashboard from "./modules/dashboard";
import other from "./modules/other";
import pages from "./modules/pages";
Vue.use(VueRouter);

const routes = [
  {
    path: "/pages",
    component: () => import("@/layouts/pages/Index"),
    children: pages
  },
  {
    path: "/",
    component: () => import("@/layouts/dashboard/Index"),
    children: dashboard
  },
  ...other
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

export default router;
