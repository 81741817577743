import { login, logout, getInfo } from "@/api/user";
import { getAuth, setAuth } from "@/utils/auth";

const state = {
  auth: getAuth(),
  name: "",
  id: "",
  username: "",
  organization: null,
  role: null
};

const mutations = {
  SET_AUTH: (state, payload) => {
    state.auth = payload;
    setAuth(payload);
  },
  SET_ID: (state, id) => {
    state.id = id;
  },
  SET_NAME: (state, name) => {
    state.name = name;
  },
  SET_ROLE: (state, role) => {
    state.role = role;
  },
  SET_USERNAME: (state, username) => {
    state.username = username;
  },
  SET_ORGANIZATION(state, organization) {
    state.organization = organization;
  }
};

const actions = {
  login({ commit }, userInfo) {
    const { username, password } = userInfo;
    return new Promise((resolve, reject) => {
      login({ username: username.trim(), password: password })
        .then(res => {
          commit("SET_AUTH", true);
          resolve(res.data.homeUrl);
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  // get user info
  getInfo({ commit }) {
    return new Promise((resolve, reject) => {
      getInfo()
        .then(response => {
          const { data } = response;
          if (!data) {
            reject("Verification failed, please Login again.");
          }
          const { id, name, username, organization, role } = data;
          commit("SET_NAME", name);
          commit("SET_ID", id);
          commit("SET_USERNAME", username);
          commit("SET_ORGANIZATION", organization);
          commit("SET_ROLE", role);

          resolve(data);
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  // user logout
  logout({ commit }) {
    return new Promise((resolve, reject) => {
      logout()
        .then(() => {
          commit("SET_AUTH", false);
          resolve();
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  // remove auth
  removeAuth({ commit }) {
    return new Promise(resolve => {
      commit("SET_AUTH", false);
      resolve();
    });
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  actions
};
