import Vue from "vue";
import App from "./App.vue";
import store from "./store";
import router from "./router";

import vuetify from "./plugins/vuetify";
import "./sass/custom.scss";
import "./plugins/base";
import "./filter";
import "./permission";
import Vuelidate from "vuelidate";
import VuetifyConfirm from "vuetify-confirm";
import i18n from "./i18n";
import axios from "axios";

Vue.use(VuetifyConfirm, { vuetify });
Vue.use(Vuelidate);

Vue.config.productionTip = false;
axios.default.withCredentials = true;
Vue.prototype.$snackbar = (text, type, timeout = 2000) => {
  store.dispatch("app/showSnackbar", { text, type, timeout });
};
Vue.prototype.$loader = loader => {
  store.dispatch("app/setLoader", loader);
};

new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: h => h(App)
}).$mount("#app");
