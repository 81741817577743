export default [
  {
    path: "/profile",
    name: "Hồ sơ cá nhân",
    component: () => import("@/views/app/profile/index")
  },
  {
    name: "Tổ chức",
    path: "/organizations",
    meta: {
      role: "Sa"
    },
    component: () => import("@/views/app/organization/index")
  },
  {
    name: "Lĩnh vực kinh doanh",
    path: "/business-areas",
    meta: {
      role: "Sa"
    },
    component: () => import("@/views/app/business-area/index")
  },
  {
    name: "Icon",
    path: "/icons",
    meta: {
      role: "Sa"
    },
    component: () => import("@/views/app/icon/index")
  },
  {
    name: "Loại địa điểm",
    path: "/edit/:code/place-types",
    meta: {
      role: "Sa"
    },
    component: () => import("@/views/app/place-type/index")
  },
  {
    name: "Thông tin",
    path: "/edit/:code/information",
    meta: {
      role: "Sa"
    },
    component: () => import("@/views/app/information/index")
  },
  {
    name: "Địa điểm",
    path: "/edit/:code/places",
    meta: {
      role: "Sa"
    },
    component: () => import("@/views/app/place/index")
  },
  {
    name: "Bản đồ",
    path: "/edit/:code/maps",
    meta: {
      role: "Sa"
    },
    component: () => import("@/views/app/maps/index")
  }
];
