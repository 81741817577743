export default [
  {
    name: "Login",
    path: "login",
    component: () => import("@/views/pages/Login")
  },

  {
    name: "404",
    path: "/404",
    component: () => import("@/views/pages/404")
  }
];
